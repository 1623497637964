<template>
  <div>
    <dashboard-page-title
      :showSearch="false"
      :showFilter="false"
      :showMainActions="false"
    >
      طلب تفعيل متجر
    </dashboard-page-title>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <b-row>
          <b-col md="9">
            <b-card>
              <template v-slot:header>
                <h5 class="text-primary">بيانات مالك المتجر</h5>
              </template>
              <b-row>
                <b-col md="4" class="mb-3">
                  <input-form
                    placeholder="الاسم بالكامل"
                    label="الاسم بالكامل"
                    v-model="data.full_name"
                    :disabled="$route.name === 'inActiveMarket' || $route.name === 'showMerchant' "
                    name="full_name"
                    validate="required"
                  ></input-form>
                </b-col>
                <b-col md="4" class="mb-3">
                  <input-form
                    placeholder="الهوية"
                    label="الهوية"
                    v-model="data.national_id"
                    :disabled="$route.name === 'inActiveMarket' || $route.name === 'showMerchant' "
                    name="national_id"
                    validate="required"
                  ></input-form>
                </b-col>
                <b-col md="4" class="mb-3">
                  <input-form
                    placeholder="التليفون"
                    label="التليفون"
                    v-model="data.phone"
                    :disabled="$route.name === 'inActiveMarket' || $route.name === 'showMerchant' "
                    name="phone"
                    validate="required"
                  ></input-form>
                </b-col>
              </b-row>
              <b-row> </b-row>
            </b-card>
            <b-card class="mt-4">
              <template v-slot:header>
                <h5 class="text-primary">بيانات المتجر</h5>
              </template>
              <b-row>
                <b-col md="6" class="mb-3">
                  <input-form
                    placeholder="الاسم بالعربي"
                    label="اسم المتجر باللغة العربية"
                    v-model="data.ar_name"
                    :disabled="$route.name === 'inActiveMarket' || $route.name === 'showMerchant' "
                    name="ar_name"
                    validate="required"
                  ></input-form>
                </b-col>
                <b-col md="6" class="mb-3">
                  <input-form
                    placeholder="الاسم بالانجليزي"
                    label="اسم المتجر باللغة الانجليزية"
                    v-model="data.en_name"
                    :disabled="$route.name === 'inActiveMarket' || $route.name === 'showMerchant' "
                    name="en_name"
                    validate="required"
                    dir="rtl"
                  ></input-form>
                </b-col>

                <b-col md="12" class="mb-3">
                  <div v-if="$route.name === 'showMerchant' || $route.name === 'inActiveMarket'">
                    <input-form
                      placeholder="نوع المتجر"
                      label="نوع المتجر"
                      v-model="data.category.name"
                      disabled
                      name="category"
                    ></input-form>
                  </div>
                  <div v-else>
                    <label class="joining-label">نوع المتجر</label>
                    <main-select
                    class="mb-3 joining-label"
                    validate="required"
                    :reduce="category => category.id"
                    :options="allCategories"
                    :disabled="$route.name === 'inActiveMarket' || $route.name === 'showMerchant' "
                    v-model="data.category.id"
                    :searchable="false"
                    label="name"
                    dir="rtl"
                  />
                  </div>
                </b-col>
                <b-col md="4">
                  <div v-if="$route.name === 'showMerchant' || $route.name === 'inActiveMarket'">
                    <input-form
                      placeholder="المدينة"
                      label="المدينة"
                      v-model="data.city.name"
                      style="font-size: 14px; line-height: 1.8;"
                      disabled
                      name="city"
                    ></input-form>
                  </div>
                  <div v-else>
                    <label class="joining-label">المدينة</label>
                    <main-select
                    class="mb-3 joining-label"
                    :class="{ 'is-disabled': isDisabled }"
                    :validate="'required'"
                    :name="city"
                    :reduce="city => city.id"
                    :placeholder="city"
                    :options="allCity"
                    v-model="data.city.id"
                    :disabled="$route.name === 'inActiveMarket' || $route.name === 'showMerchant' "
                    label="name"
                    dir="rtl"
                    />
                  </div>
                </b-col>
                <b-col md="8" class="mb-3">
                  <div>
                    <input-form
                      placeholder="العنوان"
                      label="العنوان"
                      v-model="data.address"
                      :disabled="$route.name === 'inActiveMarket' || $route.name === 'showMerchant' "
                      name="address"
                      validate="required"
                    ></input-form>
                  </div>
                </b-col>
                <b-col md="6" class="mb-3">
                  <textarea-form
                    placeholder="وصف المتجر"
                    label="وصف المتجر"
                    v-model="data.about"
                    :disabled="$route.name === 'inActiveMarket' || $route.name === 'showMerchant' "
                    name="description"
                    validate="required"
                  ></textarea-form>
                </b-col>
                <b-col md="6" class="mb-3">
                  <textarea-form
                    placeholder="الشروط الاساسية"
                    label="الشروط الاساسية"
                    v-model="data.policies"
                    :disabled="$route.name === 'inActiveMarket' || $route.name === 'showMerchant' "
                    name="policies"
                    validate="required"
                  ></textarea-form>
                </b-col>
              </b-row>
              <b-row> </b-row>
            </b-card>
          </b-col>
          <b-col md="3">
            <b-card class="mb-4">
              <cropper-images
                label="صورة الشعار"
                @cropper-save="(file) => saveMasterImage(file, 'logo')"
                :progressLoading="loadingLogo"
                :showProgress="false"
                :multi="false"
                :imageUrl="data.logo"
                v-if="$route.name == 'editMarket'"
              />
              <div v-else>
                <span>صورة الشعار</span>
                <hr />
                <div class="d-flex gap-3" style="gap: 10px">
                  <img
                    :src="data.logo"
                    class="rounded-circle object-fit-cover"
                    style="width: 80px; height: 40px"
                  />
                  <b-button
                    variant="outline-primary"
                    id="show-btn"
                    @click="showModal({ url: data.logo, title: 'صورة الشعار' })"
                    >عرض الصورة</b-button
                  >
                </div>
              </div>
            </b-card>
            <b-card class="mb-4">
              <cropper-images
                label="غلاف المتجر"
                @cropper-save="(file) => saveMasterImage(file, 'cover')"
                :progressLoading="loadingLogo"
                :showProgress="false"
                :multi="false"
                :imageUrl="data.cover"
                v-if="$route.name == 'editMarket'"
              />
              <div v-else>
                <span>غلاف المتجر</span>
                <hr />
                <div class="d-flex gap-3" style="gap: 10px">
                  <img
                    :src="data.cover"
                    class="rounded-circle object-fit-cover"
                    style="width: 80px; height: 40px"
                  />
                  <b-button
                    variant="outline-primary"
                    id="show-btn"
                    @click="
                      showModal({ url: data.cover, title: 'غلاف المتجر' })
                    "
                    >عرض الصورة</b-button
                  >
                </div>
              </div>
            </b-card>
            <b-card class="mb-4">
              <cropper-images
                label="شهادة ضريبة"
                @cropper-save="(file) => saveMasterImage(file, 'vat_certificate')"
                :progressLoading="loadingLogo"
                :showProgress="false"
                :multi="false"
                :imageUrl="data.vat_certificate"
                v-if="$route.name == 'editMarket'"
              />
              <div v-else>
                <span>شهادة ضريبة</span>
                <hr />
                <div class="d-flex gap-3" style="gap: 10px">
                  <img
                    :src="data.vat_certificate"
                    class="rounded-circle object-fit-cover"
                    style="width: 80px; height: 40px"
                  />
                  <b-button
                    variant="outline-primary"
                    id="show-btn"
                    @click="
                      showModal({
                        url: data.vat_certificate,
                        title: 'شهادة ضريبة'
                      })
                    "
                    >عرض الصورة</b-button
                  >
                </div>
              </div>
            </b-card>
            <b-card class="mb-4">
              <cropper-images
                label="السجل التجارى"
                @cropper-save="(file) => saveMasterImage(file, 'commercial_registry')"
                :progressLoading="loadingLogo"
                :showProgress="false"
                :multi="false"
                :imageUrl="data.commercial_registry"
                v-if="$route.name == 'editMarket'"
              />
              <div v-else>
                <span>السجل التجاري</span>
                <hr />
                <div class="d-flex gap-3" style="gap: 10px">
                  <img
                    :src="data.commercial_registry"
                    class="rounded-circle object-fit-cover"
                    style="width: 80px; height: 40px"
                  />
                  <b-button
                    variant="outline-primary"
                    id="show-btn"
                    @click="
                      showModal({
                        url: data.commercial_registry,
                        title: 'السجل التجاري'
                      })
                    "
                    >عرض الصورة</b-button
                  >
                </div>
              </div>
            </b-card>
          </b-col>
        </b-row>
        <b-row class="mt-5" v-if="$route.name === 'inActiveMarket'">
          <b-col
            md="9"
            class="d-flex justify-content-center align-items-center"
            style="gap: 10px"
          >
            <div>
              <b-button
                variant="primary"
                class="px-5 py-3"
                id="show-btn"
                v-if="!loadingAccept"
                @click="acceptMarket"
                >قبول</b-button
              >
              <b-button
                v-else
                variant="primary"
                class="text-white px-5 py-3"
                disabled
                type="submit"
              >
                <b-spinner small type="grow"></b-spinner>
                Loading...
              </b-button>
            </div>
            <div>
              <b-button
                variant="info"
                class="px-5 py-3"
                id="show-btn"
                v-if="!loadingComplate"
                @click="complateData"
                >استكمال البيانات</b-button
              >
              <b-button
                v-else
                variant="primary"
                class="text-white px-5 py-3"
                disabled
                type="submit"
              >
                <b-spinner small type="grow"></b-spinner>
                Loading...
              </b-button>
            </div>
            <div>
              <b-button
                variant="outline-danger"
                class="px-5 py-3"
                id="show-btn"
                v-if="!loadingReject"
                @click="showSuspensedModal"
                >رفض</b-button
              >
              <b-button
                v-else
                variant="outline-danger"
                class="text-white px-5 py-3"
                disabled
                type="submit"
              >
                <b-spinner small type="grow"></b-spinner>
                Loading...
              </b-button>
            </div>
          </b-col>
        </b-row>

        <b-row v-if="$route.name == 'editMarket'">
          <b-col
            md="9"
            class="d-flex justify-content-center align-items-center mt-5"
          >
            <b-button
              variant="primary"
              class="px-5 py-3"
              type="submit"
              v-if="!loadingSubmit"
              >تعديل</b-button
            >
            <b-button
              v-else
              variant="primary"
              class="text-white px-5 py-3"
              disabled
              type="submit"
            >
              <b-spinner small type="grow"></b-spinner>
              Loading...
            </b-button>
          </b-col>
        </b-row>
      </form>
    </ValidationObserver>

    <b-modal
      ref="modal-photo"
      id="modal-photo"
      centered
      hide-footer
    >
      <template #modal-title>
        {{ selectedPhoto.title }}
      </template>
      <div class="image-container d-block text-center">
        <img :src="selectedPhoto.url" class="img-fluid" />
      </div>
    </b-modal>

    <b-modal
      id="suspendedModal"
      size="lg"
      title="سبب ايقاف المتجر"
      style="direction: rtl"
      centered
      hide-footer
    >
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(onSubmit)">
          <b-row class="d-flex flex-wrap">
            <b-col>
              <textarea-form
                placeholder="السبب"
                v-model="reason"
                label="سبب مختصر"
                name="reason"
                validate="required"
              ></textarea-form>
            </b-col>
          </b-row>
        </form>
      </ValidationObserver>

      <div class="d-flex justify-content-center mt-2">
        <b-button
          v-if="saveButton"
          class="btn btn-primary float-right"
          variant="primary"
          disabled
        >
          <spinner-loading text="Loading" />
        </b-button>
        <b-button
          variant="primary"
          v-else
          class="w-50"
          @click="handleSuspendMarket"
          >Save</b-button
        >
      </div>
    </b-modal>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import marketPlaceServices from '../services/marketPlace'
import commonMixin from '@/mixins/commonMixin'
export default {
  components: {},
  mounted () {
    core.index()
  },
  mixins: [commonMixin],
  data () {
    return {
      reason: '',
      suspendedId: '',
      saveButton: false,
      selectedPhoto: {},
      loadingAccept: false,
      loadingReject: false,
      loadingSubmit: false,
      loadingComplate: false,
      // loadingLogo: 0,
      data: {
        full_name: '',
        phone: '',
        national_id: '',
        ar_name: '',
        en_name: '',
        category: {},
        city: {},
        address: '',
        about: '',
        policies: '',
        logo: '',
        cover: '',
        vat_certificate: '',
        commercial_registry: ''
      },
      allCity: [],
      allCategories: []
    }
  },
  methods: {
    getAllCities () {
      marketPlaceServices.getCities().then(res => {
        this.allCity = res.data.data
      })
    },
    getMarketPlace () {
      marketPlaceServices.getMarket(this.$route.params.id).then((res) => {
        this.data = {
          full_name: res.data.full_name,
          phone: res.data.phone,
          national_id: res.data.national_id,
          ar_name: res.data.ar_name,
          en_name: res.data.en_name,
          category: res.data.category,
          city: res.data.city,
          address: res.data.address,
          about: res.data.about,
          policies: res.data.policies,
          logo: res.data.logo,
          cover: res.data.cover,
          vat_certificate: res.data.vat_certificate,
          commercial_registry: res.data.commercial_registry
        }
      })
    },
    saveMasterImage (file, index) {
      const formData = new FormData()
      formData.append('file', file.image)
      const options = {
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent
          const percent = Math.floor((loaded * 100) / total)
          this.loadingLogo = percent
        }
      }
      this.commonUploadImages(formData, options)
        .then((res) => {
          this.data[index] = res.data.url
          this.showSuccessUploadFile()
        })
        .catch((err) => {
          console.log(err)
        })
    },
    showModal (item) {
      this.$refs['modal-photo'].show()
      this.selectedPhoto = { url: item.url, title: item.title }
    },
    showSuspensedModal () {
      this.suspendedId = this.$route.params.id
      this.$bvModal.show('suspendedModal')
    },
    acceptMarket () {
      this.loadingAccept = true
      marketPlaceServices
        .marketStatus(this.$route.params.id, 'completed')
        .then((res) => {
          core.showSnackbar('success', 'تم  قبول المتجر بنجاح')
          this.$router.push({ name: 'pendingMerchant' })
        })
        .finally(() => {
          this.loadingAccept = false
        })
    },
    complateData () {
      this.loadingComplate = true
      marketPlaceServices
        .marketStatus(this.$route.params.id, 'processing')
        .then((res) => {
          core.showSnackbar('success', 'تم  استكمال الطلب بنجاح')
          this.$router.push({ name: 'pendingMerchant' })
        })
        .finally(() => {
          this.loadingComplate = false
        })
    },
    handleSuspendMarket () {
      this.saveButton = true
      marketPlaceServices
        .marketStatus(this.suspendedId, 'rejected', { mail_body: this.reason })
        .then((res) => {
          core.showSnackbar('success', 'تم  رفض المتجر بنجاح')
          this.saveButton = false
          this.$bvModal.hide('suspendedModal')
          this.$router.push({ name: 'pendingMerchant' })
        })
    },
    getStoreCategories () {
      // marketPlace().get("stores-categories")
      marketPlaceServices.getAllStores().then((res) => {
        this.allCategories = res.data.data
      })
    },
    onSubmit () {
      if (this.$route.name === 'editMarket') {
        this.loadingSubmit = true
        marketPlaceServices
          .editMarket(this.$route.params.id, this.data)
          .then((res) => {
            this.$router.go(-1)
          })
          .finally(() => {
            this.loadingSubmit = false
          })
      }
    }
  },
  created () {
    if (this.$route.params.id) {
      this.getAllCities()
      this.getMarketPlace()
      this.getStoreCategories()
      this.$root.$on('showSuspensedModal', this.showSuspensedModal)
    }
  }
}
</script>

<style>
.vs__dropdown-toggle {
  background: #fff !important;
  border-radius: 10px !important;
}
.image-container {
  width: 100%;
}
.image-container img {
  width: 100%;
}
.is-disabled {
  background-color: #f0f0f0;
  color: #888;
  border: 1px solid #ccc;
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.6;
}
</style>
