export default {
  transportation: 'transportation',
  transportationInActive: 'Inactive drivers',
  driverRequest: 'Driver activation request',
  process: 'process',
  companies: 'companies',
  drivers: 'drivers',
  vehiclesType: 'vehicles Type',
  vehiclesMake: 'vehicles Make',
  vehicles: 'vehicles',
  companySettings: 'company settings',
  driversSettings: 'drivers settings'
}
